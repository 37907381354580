.ImageCropperWrap {
  overflow: hidden;

  .ImageCropper {
    position: relative;

    .ImageCropperContainer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .reactEasyCrop_Container {
        .reactEasyCrop_CropArea {
          border: none !important;
        }
      }
    }
  }
}

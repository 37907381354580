.flex-content-vc-hl {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  text-align: left !important;
}

.flex-content-vc-hc {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.flex-content-vc-hr {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  text-align: right !important;
}

.flex-content-hl {
  display: flex !important;
  justify-content: flex-start !important;
}

.flex-content-hc {
  display: flex !important;
  justify-content: center !important;
  text-align: center;
}

.flex-content-hr {
  display: flex !important;
  justify-content: flex-end !important;
}

.flex-content-vc {
  display: flex !important;
  align-items: center !important;
}

.flex-content-vt {
  display: flex !important;
  align-items: flex-start !important;
}

.flex-content-vb {
  display: flex !important;
  align-items: flex-end !important;
}

.bg-cover-black {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
}

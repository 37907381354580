.navbar-container {
  .main-modal-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1051;
    display: none;
  }

  .explore,
  .diamond,
  .image,
  .chains-circle,
  .profile,
  .toggle,
  .call,
  .two-two-mode,
  .list-mode {
    width: 31px !important;
  }
}

#toast-container {
  &.toast-top-center-custom {
    & > div {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  > div {
    padding: 15px 15px 15px 50px !important;

    &.toast-no-icon {
      padding: 15px !important;
      text-align: center;
    }
  }

  > .toast {
    background-size: 1rem !important;
    background-repeat: no-repeat;
  }

  > .toast-no-icon {
    background-image: none !important;
  }

  > .toast-dark {
    background-color: #181c21;
    color: #fff;
  }

  > .toast-primary {
    background-color: #26b4ff;
    color: #fff;
  }

  > .toast-allergen {
    backdrop-filter: blur(10px);
    background-color: #FFFFFF15;
  }
}

.toast-top-center-custom {
  top: 12rem;
  right: 0;
  width: 100%;
}

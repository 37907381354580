@charset "UTF-8";
.ui-img-grayscale {
  opacity: 0.4;
  filter: grayscale(1);
}
.ui-img-grayscale.hoverable {
  transition: all 0.3s;
}
.ui-img-grayscale.hoverable:hover, .ui-img-grayscale.hoverable:focus {
  opacity: 1;
  filter: none;
}

.ui-w-140 {
  width: 140px !important;
  height: auto;
}

.ui-w-120 {
  width: 120px !important;
  height: auto;
}

.ui-w-100 {
  width: 100px !important;
  height: auto;
}

.ui-w-80 {
  width: 80px !important;
  height: auto;
}

.ui-w-60 {
  width: 60px !important;
  height: auto;
}

.ui-w-50 {
  width: 50px !important;
  height: auto;
}

.ui-w-40 {
  width: 40px !important;
  height: auto;
}

.ui-w-30 {
  width: 30px !important;
  height: auto;
}

.ui-w-20 {
  width: 20px !important;
  height: auto;
}

.ui-mh-100vh {
  min-height: 100vh;
}

.ui-square,
.ui-rect,
.ui-rect-30,
.ui-rect-60,
.ui-rect-67,
.ui-rect-75 {
  position: relative !important;
  display: block !important;
  padding-top: 100% !important;
  width: 100% !important;
}

.ui-square {
  padding-top: 100% !important;
}

.ui-rect {
  padding-top: 50% !important;
}

.ui-rect-30 {
  padding-top: 30% !important;
}

.ui-rect-60 {
  padding-top: 60% !important;
}

.ui-rect-67 {
  padding-top: 67% !important;
}

.ui-rect-75 {
  padding-top: 75% !important;
}

.ui-square-content,
.ui-rect-content {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.ui-icon {
  display: inline-block;
  width: 46px;
  height: 46px;
  border: 2px solid;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  line-height: calc(46px - 4px);
}
.ui-icon.border-0 {
  line-height: 46px;
}

.ui-icon-sm {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: calc(30px - 4px);
}
.ui-icon-sm.border-0 {
  line-height: 30px;
}

.ui-stars,
.ui-star,
.ui-star > * {
  height: 1.1em;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.ui-stars {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.ui-star {
  position: relative;
  display: block;
  float: left;
  width: 1.1em;
  height: 1.1em;
  text-decoration: none !important;
  font-size: 1.1em;
  line-height: 1;
  user-select: none;
}
[dir=rtl] .ui-star {
  float: right;
}
.ui-star + .ui-star {
  margin-left: -0.1em;
}
[dir=rtl] .ui-star + .ui-star {
  margin-right: -0.1em;
  margin-left: 0;
}
.ui-star > *,
.ui-star > *::before,
.ui-star > *::after {
  position: absolute;
  left: 0.55em;
  height: 100%;
  font-size: 1em;
  line-height: 1;
  transform: translateX(-50%);
}
[dir=rtl] .ui-star > *,
[dir=rtl] .ui-star > *::before,
[dir=rtl] .ui-star > *::after {
  right: 0.55em;
  left: auto;
  transform: translateX(50%);
}
.ui-star > * {
  top: 0;
  width: 100%;
  text-align: center;
}
.ui-star > *:first-child {
  z-index: 10;
  display: none;
  overflow: hidden;
  color: #FFE924;
}
.ui-star > *:last-child {
  z-index: 5;
  display: block;
}
.ui-star.half-filled > *:first-child {
  width: 50%;
  transform: translateX(-100%);
}
[dir=rtl] .ui-star.half-filled > *:first-child {
  transform: translateX(100%);
}
.ui-star.filled > *:first-child, .ui-star.half-filled > *:first-child {
  display: block;
}
.ui-star.filled > *:last-child {
  display: none;
}

.ui-stars.hoverable .ui-star > *:first-child {
  display: block;
}

.ui-stars.hoverable .ui-star:first-child:not(.filled) > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.filled) ~ .ui-star > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.half-filled) > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.half-filled) ~ .ui-star > *:first-child {
  display: none;
}

.ui-stars.hoverable .ui-star.filled > *:first-child,
.ui-stars.hoverable .ui-star.half-filled > *:first-child {
  display: block !important;
}

.ui-stars.hoverable:hover .ui-star > *:first-child {
  display: block !important;
  width: 100% !important;
  transform: translateX(-50%) !important;
}
[dir=rtl] .ui-stars.hoverable:hover .ui-star > *:first-child {
  transform: translateX(50%) !important;
}

.ui-stars.hoverable .ui-star:hover ~ .ui-star > *:first-child {
  display: none !important;
}
.ui-stars.hoverable .ui-star:hover ~ .ui-star > *:last-child {
  display: block !important;
}

.ui-list {
  padding: 0;
}
.ui-list > li {
  margin-bottom: 8px;
  list-style: none;
}
.ui-list > li::before {
  content: "•";
  display: inline-block;
  margin-right: 12px;
  font-weight: 700;
}
[dir=rtl] .ui-list > li::before {
  margin-right: 0;
  margin-left: 12px;
}

.ui-bg-cover {
  background-color: rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: cover;
}

.ui-bg-fixed {
  background-attachment: fixed;
}

.ui-bg-overlay-container,
.ui-bg-video-container {
  position: relative;
}
.ui-bg-overlay-container > *,
.ui-bg-video-container > * {
  position: relative;
}

.ui-bg-overlay-container .ui-bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.ui-bg-video-container .ui-bg-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: hidden;
}
.ui-bg-video-container .ui-bg-video video {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.ui-text-block {
  line-height: 1.8;
}
.ui-text-block > p:last-child {
  margin-bottom: 0;
}

.material-style .ui-icon {
  border-color: rgba(24, 28, 33, 0.06);
}
.material-style .ui-bordered {
  border: 1px solid rgba(24, 28, 33, 0.06);
}
.material-style .ui-star > *:last-child {
  color: rgba(24, 28, 33, 0.1);
}
.material-style .ui-list > li::before {
  color: rgba(24, 28, 33, 0.2);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .material-style .ui-mh-100vh::after {
    content: "";
    display: block;
    flex: 0 0 0%;
    min-height: 100vh;
    width: 0;
    font-size: 0;
  }
  .material-style .ui-mh-100vh.jumbotron::after {
    min-height: calc(100vh - 6rem);
  }
  .material-style .ui-mh-100vh.flex-column::after {
    display: none;
  }
}
@media all and (min-width: 576px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .material-style .ui-mh-100vh.jumbotron::after {
    min-height: calc(100vh - 12rem);
  }
}

.badge.ui-product-badge {
  align-self: flex-start;
  padding: 0.1875rem 0.375rem;
  font-size: 0.625rem;
}

.ui-product-color {
  display: inline-block;
  overflow: hidden;
  margin: 0.144em;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 10rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  vertical-align: middle;
}

.ui-product-color-lg {
  width: 1rem;
  height: 1rem;
}

.ui-product-color-sm {
  width: 0.75rem;
  height: 0.75rem;
}

.ui-payment {
  height: 40px;
}

.ui-payment-small {
  height: 30px;
}

.ui-statistics-icon {
  width: 2.875rem;
  height: 2.875rem;
  font-size: 1.125rem;
  line-height: calc(2.875rem - 4px);
}
.ui-statistics-icon.border-0 {
  line-height: 2.875rem;
}

.ui-feed-icon-container {
  position: relative;
}

.ui-feed-icon {
  position: absolute;
  top: 0;
  right: -0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  font-size: 0.75rem;
  line-height: 1.25rem;
}
[dir=rtl] .ui-feed-icon {
  right: auto;
  left: -0.625rem;
}

.ui-todo-item .custom-control-input:checked ~ .custom-control-label {
  text-decoration: line-through;
}
.ui-todo-item .custom-control-input:checked ~ .ui-todo-badge,
.ui-todo-item .custom-control-input:checked ~ .custom-control-label .ui-todo-badge {
  display: none;
}

.ui-activity-icon {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: calc(2.5rem - 4px);
}
.ui-activity-icon.border-0 {
  line-height: 2.5rem;
}

.material-style .ui-todo-item .custom-control-input:checked ~ .custom-control-label {
  color: #a3a4a6;
}

.ui-company-text {
  font-size: 15px;
  line-height: 1.8;
}

.ui-device {
  position: relative;
  user-select: none !important;
}
.ui-device:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.ui-device .device-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.ui-device .device-content {
  position: absolute;
  top: 0;
  z-index: 2;
  overflow: hidden;
}
.ui-device .device-content:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.ui-device .device-content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.ui-device.macbook {
  width: 1227px;
}
.ui-device.macbook:before {
  padding-top: 57.8647106764%;
}
.ui-device.macbook .device-content {
  margin-top: 4.3194784026%;
  margin-left: 12.4694376528%;
  width: 75.1526242869%;
}
[dir=rtl] .ui-device.macbook .device-content {
  margin-right: 12.4694376528%;
  margin-left: 0;
}
.ui-device.macbook .device-content:before {
  padding-top: 62.4728850325%;
}

.ui-device.iphone {
  width: 302px;
}
.ui-device.iphone:before {
  padding-top: 209.1549295775%;
}
.ui-device.iphone .device-content {
  z-index: 1;
  margin-top: 19.5804195804%;
  margin-left: 4.5801526718%;
  width: 91.6083916084%;
  border-radius: 0 0 12% 12%/0 0 4% 4%;
}
[dir=rtl] .ui-device.iphone .device-content {
  margin-right: 4.5801526718%;
  margin-left: 0;
}
.ui-device.iphone .device-content:before {
  padding-top: 200.7633587786%;
}
.ui-device.iphone .device-img {
  z-index: 2;
}

.ui-window {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 23px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  user-select: none;
}
.ui-window:before {
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  height: 23px;
  background: #e5e5e5;
}
.ui-window .window-content {
  width: 100%;
}
.ui-window .window-content img {
  display: block;
  width: 100%;
}

.ui-window:before, .ui-window:after,
.ui-window .window-content:before,
.ui-window .window-content:after {
  content: "";
  position: absolute;
  display: block;
}

.ui-window:after,
.ui-window .window-content:before,
.ui-window .window-content:after {
  top: 6px;
  z-index: 4;
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.ui-window:after {
  left: 10px;
  background: #ccc;
}

.ui-window .window-content:before {
  left: 26px;
  background: #ccc;
}

.ui-window .window-content:after {
  left: 43px;
  background: #ccc;
}

.jumbotron.ui-hero {
  display: flex;
  margin: 0;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.jumbotron.ui-hero > .container,
.jumbotron.ui-hero > .container-fluid {
  display: flex;
}
.jumbotron.ui-hero > .container > .row,
.jumbotron.ui-hero > .container-fluid > .row {
  align-items: center;
}

.ui-presentation-left, .ui-presentation-left.ui-device,
.ui-presentation-right,
.ui-presentation-right.ui-device {
  width: 100%;
}

.ui-customer-review {
  font-size: 1.125rem;
  line-height: 1.75;
}
.ui-customer-review::before, .ui-customer-review::after {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.5em;
  line-height: 1.125rem;
  opacity: 0.15;
}
.ui-customer-review:before {
  content: "“";
}
.ui-customer-review:after {
  content: "”";
}

.material-style .ui-hero > .container > .row {
  width: calc(100% + 1.5rem);
}
.material-style .ui-customer-review {
  font-weight: 300;
}
.material-style .ui-customer-review::before, .material-style .ui-customer-review::after {
  font-weight: 700;
}
@media (min-width: 992px) {
  .material-style .ui-presentation-left, .material-style .ui-presentation-left.ui-device,
  .material-style .ui-presentation-right,
  .material-style .ui-presentation-right.ui-device {
    width: 150%;
  }
}
@media (min-width: 1200px) {
  .material-style .ui-presentation-left, .material-style .ui-presentation-left.ui-device,
  .material-style .ui-presentation-right,
  .material-style .ui-presentation-right.ui-device {
    width: 160%;
  }
}

.ui-play-icon {
  margin-right: -0.25em;
}
[dir=rtl] .ui-play-icon {
  margin-right: 0;
  margin-left: -0.25em;
}

.ui-timeline {
  position: relative;
  padding-left: 3.75rem;
}
.ui-timeline::after {
  content: "";
  display: block;
  clear: both;
}

.ui-timeline-track-bg {
  background: #e7e7e7;
}

.dark-style .ui-timeline-track-bg {
  background: #3f4248;
}

.ui-timeline::before,
.ui-timeline-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  z-index: -1;
  background: #e7e7e7;
}

.dark-style .ui-timeline::before,
.dark-style .ui-timeline-item::after {
  background: #3f4248;
}

.ui-timeline::before {
  top: 0;
  left: 0.625rem;
  width: 0.125rem;
}

.ui-timeline-item::after {
  top: 1.5625rem;
  left: -3.125rem;
  width: 2.5rem;
  height: 0.125rem;
}

.ui-timeline-item,
.ui-timeline-separator {
  position: relative;
  margin-bottom: 1.25rem;
}

.ui-timeline-separator {
  clear: both;
  margin-left: -3.75rem;
}

.ui-timeline-badge {
  position: absolute;
  top: 1.5625rem;
  left: -3.125rem;
  display: block;
  transform: translate(-50%, -50%);
}

.ui-timeline-info {
  position: absolute;
  top: 1.5625rem;
  left: -9.375rem;
  padding-right: 1.875rem;
  width: 6.25rem;
  transform: translate(0, -50%);
  display: none;
}
.ui-timeline:not(.ui-timeline-with-info) .ui-timeline-info, .ui-timeline-horizontal:not(.ui-timeline-with-info) .ui-timeline-info {
  display: none;
}

@media (min-width: 576px) {
  .ui-timeline-with-info {
    padding-left: 9.375rem;
  }
  .ui-timeline-with-info .ui-timeline-info {
    display: block;
  }
  .ui-timeline-with-info::before {
    left: 6.25rem;
  }
  .ui-timeline-with-info .ui-timeline-separator {
    margin-left: -3.125rem;
  }
  .ui-timeline-with-info .ui-timeline-separator > * {
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) {
  .ui-timeline-center {
    padding-left: 0;
  }
  .ui-timeline-center:before {
    left: 50%;
    margin-left: -0.0625rem;
  }
  .ui-timeline-center .ui-timeline-item {
    float: right;
    clear: both;
    width: calc(50% - 3.75rem);
  }
  .ui-timeline-center .ui-timeline-item::after {
    left: -3.75rem;
    width: 3.125rem;
  }
  .ui-timeline-center .ui-timeline-item .ui-timeline-badge {
    left: -3.8125rem;
  }
  .ui-timeline-center .ui-timeline-item .ui-timeline-info {
    left: -10rem;
  }
  .ui-timeline-center .ui-timeline-item-left {
    float: left;
    padding-left: 0;
  }
  .ui-timeline-center .ui-timeline-item-left::after {
    right: -3.75rem;
    left: auto;
  }
  .ui-timeline-center .ui-timeline-item-left .ui-timeline-badge {
    right: -3.8125rem;
    left: auto;
    transform: translate(50%, -50%);
  }
  .ui-timeline-center .ui-timeline-item-left .ui-timeline-info {
    right: -10rem;
    left: auto;
    padding-right: 0;
    padding-left: 1.875rem;
  }
  .ui-timeline-center .ui-timeline-separator {
    margin-right: 0;
    margin-left: 0;
    text-align: center;
  }
  .ui-timeline-center .ui-timeline-separator > * {
    transform: none;
  }
  .ui-timeline-center.ui-timeline-stacked:not(.ui-timeline-with-info) .ui-timeline-item {
    clear: right;
  }
  .ui-timeline-center.ui-timeline-stacked:not(.ui-timeline-with-info) .ui-timeline-item-left {
    clear: left;
  }
  .ui-timeline-center.ui-timeline-stacked:not(.ui-timeline-with-info) .ui-timeline-item:not(.ui-timeline-item-left) + .ui-timeline-item-left,
  .ui-timeline-center.ui-timeline-stacked:not(.ui-timeline-with-info) .ui-timeline-item-left + .ui-timeline-item:not(.ui-timeline-item-left) {
    margin-top: 3.125rem;
  }
}
[dir=rtl] .ui-timeline {
  padding-right: 3.75rem;
  padding-left: 0;
}
[dir=rtl] .ui-timeline::before {
  right: 0.625rem;
  left: auto;
}
[dir=rtl] .ui-timeline-item::after {
  right: -3.125rem;
  left: auto;
}
[dir=rtl] .ui-timeline-separator {
  margin-right: -3.75rem;
  margin-left: 0;
}
[dir=rtl] .ui-timeline-badge {
  right: -3.125rem;
  left: auto;
  transform: translate(50%, -50%);
}
[dir=rtl] .ui-timeline-info {
  right: -9.375rem;
  left: auto;
  padding-right: 0;
  padding-left: 1.875rem;
}
@media (min-width: 576px) {
  [dir=rtl] .ui-timeline-with-info {
    padding-right: 9.375rem;
  }
  [dir=rtl] .ui-timeline-with-info::before {
    right: 6.25rem;
    left: auto;
  }
  [dir=rtl] .ui-timeline-with-info .ui-timeline-separator {
    margin-right: -3.125rem;
  }
  [dir=rtl] .ui-timeline-with-info .ui-timeline-separator > * {
    transform: translateX(50%);
  }
}
@media (min-width: 768px) {
  [dir=rtl] .ui-timeline-center {
    padding-right: 0;
  }
  [dir=rtl] .ui-timeline-center:before {
    right: 50%;
    margin-right: -0.0625rem;
    margin-left: 0;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-item {
    float: left;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-item::after {
    right: -3.75rem;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-item .ui-timeline-badge {
    right: -3.8125rem;
    left: auto;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-item .ui-timeline-info {
    right: -10rem;
    left: auto;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-item-left {
    float: right;
    padding-right: 0;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-item-left::after {
    right: auto;
    left: -3.75rem;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-item-left .ui-timeline-badge {
    right: auto;
    left: -3.8125rem;
    transform: translate(-50%, -50%);
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-item-left .ui-timeline-info {
    right: auto;
    left: -10rem;
    padding-right: 1.875rem;
    padding-left: 0;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-separator {
    margin-right: 0;
  }
  [dir=rtl] .ui-timeline-center .ui-timeline-separator > * {
    transform: none;
  }
  [dir=rtl] .ui-timeline-center.ui-timeline-stacked:not(.ui-timeline-with-info) .ui-timeline-item {
    clear: left;
  }
  [dir=rtl] .ui-timeline-center.ui-timeline-stacked:not(.ui-timeline-with-info) .ui-timeline-item-left {
    clear: right;
  }
}
$safe-area-inset-top-env: env(safe-area-inset-top);
$safe-area-inset-top-constant: constant(safe-area-inset-top);
$safe-area-inset-bottom-env: env(safe-area-inset-bottom);
$safe-area-inset-bottom-constant: constant(safe-area-inset-bottom);

$rest-img-height: 14rem;

$rest-img-height-big: 18.3rem;

$header-navbar-height: 44px;

$footer-navbar-height: 44px;

$font-size-base: .96rem !default;

$hours: 9.85rem;

$add-date-width: 4.18rem;

$add-hours-width: 4.9525rem;

$modal-standard-height: 68vh;

$modal-footer-height: 3.75rem;

$footer-height: 60px;

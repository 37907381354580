@import "app/app.shared.variables";
@import './demo';
@import "./vendor/styles/_custom-variables/features";

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Remove outline on desktops
@media(min-width: 720px) {
  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

// Fix horizontal scrollbar issue when .dropdown-menu-right element is shown
.layout-wrapper {
  overflow: hidden;
}

// *******************************************************************************
// * Page transition

.router-transitions {
  opacity: 1;
  transition: opacity .2s;

  body.app-loading & {
    opacity: .75;
  }
}

// *******************************************************************************
// * Animations

@include keyframes(ngFloatingElementAnimation) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html {
  .tooltip,
  .popover {
    animation: ngFloatingElementAnimation .25s;
  }

  .dropdown-menu {
    animation: ngFloatingElementAnimation .1s;
  }
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .5;
  }
}

@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(ngBottomModalAnimation) {
  from {
    opacity: 0;
    bottom: -180px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@if $enable-rtl-support {
  @include keyframes(ngRtlSlideModalAnimation) {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@include feature-rtl(false) {
  // Prevent horizontal scrollbar
  .dz-hidden-input {
    right: 0 !important;
    left: auto !important;
  }
}

// *******************************************************************************
// * Accordion

[ngbaccordion] .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: $font-size-base;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor;
  opacity: 1;
}

.backdrop-blur {
  backdrop-filter: blur(10px);
  background: #ffffff35;
}

.bottom-modal-content {
  .bottom-modal-item {
    font-weight: 500;
    font-size: 1.05rem;
  }

  .md-btn-flat {
    font-size: 1.05rem;
  }
}

.bottom-modal-content {
  height: 230px;
}
